import React from 'react'
import Markdown from 'react-markdown'

function SingleMessage({ message, sender}) {
    return (
        <div className={`${sender} single_message`}>
            <div className="bubble">
                <Markdown>{message}</Markdown>
            </div>
        </div>
    )
}

export default SingleMessage