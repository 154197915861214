import Header from './Header';
import Input from './Input';

function BubbleHolder({ chat }) {
  return (
    <div className="bubble-holder">
      {chat.images && (
        <>
          <Header
            title={chat.title}
            image={chat.images.header_image}
            colors={[chat.colors.header_text_color, chat.colors.header_bg]}
          />
          <Input
            chat_id={chat.id}
            suggestions_style={chat.colors.suggestion_style}
            Loadedsuggestions={
              chat.message_suggestions ? chat.message_suggestions : []
            }
            collect_leads={chat.collect_leads}
            initial_message={chat.initial_message}
          />
        </>
      )}
    </div>
  );
}

export default BubbleHolder;
