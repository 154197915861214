import React from 'react';

function BubbleOpener({ image, colors }) {
  // const toggleDisplay = () => {
  //   const element = document.querySelector('.bubble-holder');
  //   element.classList.toggle('show');
  // };


  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDisplay = () => {
    const element = document.querySelector('.bubble-holder');
    if (isOpen) {
      // If chatbot is open, initiate slide-out animation
      element.classList.add('animate-hide');
      setTimeout(() => {
        element.classList.remove('animate-hide', 'show');
      }, 500); // Match this duration with your CSS animation duration
    } else {
      // If chatbot is closed, initiate slide-in animation
      element.classList.add('show', 'animate-show');
      setTimeout(() => {
        element.classList.remove('animate-show');
      }, 500); // Match this duration with your CSS animation duration
    }
    setIsOpen(!isOpen); // Toggle chatbot state
  };

  // image = null;

  return (
    <div className="bubble-opener" onClick={() => toggleDisplay()}>
      {image ? (
        <img src={image} />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 640 512"
        >
          <path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z" />
        </svg>
      )}
    </div>
  );
}

export default BubbleOpener;
