import React from "react";

function Header({ title, image, colors }) {
    const toggleDisplay = () => {
        const element = document.querySelector(".bubble-holder");
        element.classList.toggle("show");
    };

    const resetChat = () => {
        localStorage.removeItem("conv_id");
        localStorage.removeItem("username");
        localStorage.removeItem("Live");
        localStorage.setItem("messages", JSON.stringify([]));
        document.querySelector(".conversation-messages").innerHTML = "";
        window.location.reload();
    }

    return (
        <div className="bubble-header" style={
            {
                color: colors[0] ? colors[0] : "#fff",
                background: colors[1] ? colors[1] : "rgb(67 19 118)"
            }
        } >
            <div className="title">
                {image && <img src={image} alt="header" />} {title}
            </div>
            <div className="header-buttons">
                <div className="reset icons" onClick={() => resetChat()} style={
                    {
                        fill: colors[0] ? colors[0] : "#fff",
                        cursor: "pointer"
                    }
                } >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-refresh-cw"><path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"/><path d="M21 3v5h-5"/><path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"/><path d="M8 16H3v5"/></svg>
                </div>
                <div className="close icons" onClick={() => toggleDisplay()} style={
                    {
                        fill: colors[0] ? colors[0] : "#fff",
                        cursor: "pointer"
                    }
                } >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                </div>
            </div>
        </div>
    );
}

export default Header;
