import React, { useState, useEffect } from "react";
import axios from 'axios';
import BubbleHolder from "./components/BubbleHolder";
import BubbleOpener from "./components/BubbleOpener";

function App({ chat_id }) {
  const [chat, setChat] = useState([]);
  // const domain = 'http://localhost:8000/';
  const domain = 'https://api.nitrobot.io/';

  useEffect(() => {
    axios.get(`${domain}api/embed-data/${chat_id}/`)
      .then(response => {
        setChat(response.data);

        const root = document.documentElement;
        root.style.setProperty('--nitrobot-header-bg', response.data.colors.header_bg ? response.data.colors.header_bg : "#4e2781");
        root.style.setProperty('--nitrobot-header-text', response.data.colors.header_text_color ? response.data.colors.header_text_color : "#ffffff")
        root.style.setProperty('--nitrobot-ai-message-bg', response.data.colors.ai_message_bg ? response.data.colors.ai_message_bg : "#4e2781");
        root.style.setProperty('--nitrobot-ai-message-text-color', response.data.colors.ai_message_text_color ? response.data.colors.ai_message_text_color : "#ffffff");
        root.style.setProperty('--nitrobot-user-message-bg', response.data.colors.user_message_bg ? response.data.colors.user_message_bg : "#4e2781");
        root.style.setProperty('--nitrobot-user-message-text-color', response.data.colors.user_message_text_color ? response.data.colors.user_message_text_color : "#ffffff");
        root.style.setProperty('--nitrobot-suggestions-bg', response.data.colors.suggestion_bg ? response.data.colors.suggestion_bg : "#4e2781");
        root.style.setProperty('--nitrobot-suggestions-text-color', response.data.colors.suggestion_text_color ? response.data.colors.suggestion_text_color : "#ffffff");
        root.style.setProperty('--nitrobot-suggestions-style', response.data.colors.suggestion_style ? response.data.colors.suggestion_style : "bg");
        root.style.setProperty('--nitrobot-body-bg', response.data.colors.body_bg ? response.data.colors.body_bg : "#ffffff");
        root.style.setProperty('--nitrobot-bubble-bg', response.data.colors.bubble_bg ? response.data.colors.bubble_bg : "#000000");
        root.style.setProperty('--nitrobot-bubble-text', response.data.colors.bubble_text_color ? response.data.colors.bubble_text_color : "#ffffff");
      })
      .catch(error => {
        console.log(error);
      });
  }, [chat_id]);

  return (
    <div className="chatbot-bubble-wrapper">
      <BubbleHolder chat={chat} />
      {chat.images && (
        <BubbleOpener image={chat.images.bubble_image} colors={chat.colors} />
      )}
    </div>
  );
}

export default App;