import React, { useState, useEffect, useRef } from 'react';
import SingleMessage from './SingleMessage';

function Input({
  chat_id,
  Loadedsuggestions,
  initial_message,
  suggestions_style,
}) {
  const [n_message, setN_message] = useState('');
  const [messages, setMessages] = useState(
    JSON.parse(localStorage.getItem('messages')) || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const messagesEndRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [suggestions, setSuggestions] = useState([]);

  // Live Part
  const [Live, setLive] = useState(false);
  const [liveChat, setLiveChat] = useState([]);
  const stored_conv_id = localStorage.getItem('conv_id');
  const ws = useRef(null);

   const domain = 'https://api.nitrobot.io';
  // const domain = 'http://localhost:8000';
  useEffect(() => {
    try {
      setSuggestions(JSON.parse(Loadedsuggestions));
    } catch {
      setSuggestions([]);
    }
  }, [Loadedsuggestions]);

  useEffect(() => {
    // Only establish the WebSocket connection if Live is true
    if (Live) {
      // console.log('Connecting to WebSocket...');
      // console.log(stored_conv_id);
      // console.log(stored_conv_id.length);
      ws.current = new WebSocket(`ws://${domain}ws/chat/${stored_conv_id}/`);

      ws.current.onopen = () => {
        // console.log('Connected to WebSocket');
        //     alert('Live Chat has been started.');
      };

      ws.current.onclose = () => alert('Live Chat has been closed.');

      ws.current.onmessage = (e) => {
        // console.log('Message received:', e.data);
        const message = JSON.parse(e.data);
        setLiveChat((prevMessages) => [...prevMessages, message]);
      };

      // Cleanup function to close the connection
      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
    } else {
      // If Live is false and a WebSocket connection exists, close it
      if (ws.current) {
        ws.current.close();
      }
    }
  }, [Live, stored_conv_id]); // React to changes in Live or stored_conv_id

  const sendMessage = (message) => {
    const payload = JSON.stringify({
      text: message,
      role: 'user', // Or 'agent'/'system' based on your logic
    });
    ws.current.send(payload);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3; // Adjust the sensitivity of the drag
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!n_message) {
      setErrorMessage('The message cannot be empty.');
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
    } else {
      setIsLoading(true);

      if (!Live) {
        try {
          setMessages((messages) => [
            ...messages,
            { message: n_message, sender: 'human' },
          ]);
          setN_message('');
          const formdata = new FormData();
          formdata.append('chat_id', chat_id);
          formdata.append('message', n_message);
          formdata.append('save_history', 'True');

          const stored_conv_id = localStorage.getItem('conv_id');
          if (stored_conv_id) {
            formdata.append('conv_id', stored_conv_id);
          }

          const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
          };

          fetch(`${domain}/api/get-answer/`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              const conv_id = data.conversation_id;
              const message = data.answer;
              const { Live } = data;
              if (Live) {
                console.log('Live is true');
                setLive(Live);
              }
              localStorage.setItem('conv_id', conv_id.trim());

              const newMessages = [
                ...messages,
                { message: n_message, sender: 'user' },
                { message, sender: 'ai' },
              ];
              setMessages(newMessages);
              localStorage.setItem('messages', JSON.stringify(newMessages));
              setN_message('');
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
            });
        } catch (error) {
          setIsLoading(false);
          setErrorMessage('There was an error. Please try again.');
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        }
      } else if (Live) {
        sendMessage(n_message);
        setN_message('');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('messages', JSON.stringify(messages));
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages, liveChat]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading]);

  return (
    <>
      <div className="conversation-messages">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="conversation-messages-inner">
          <SingleMessage key={-1} message={initial_message} sender="ai" />
          {messages &&
            messages.map((message, index) => (
              <SingleMessage
                key={index}
                message={message.message}
                sender={message.sender}
              />
            ))}
          {/* Live Chat part */}
          {Live &&
            liveChat.map((message, index) => (
              <SingleMessage
                key={index}
                message={message.content || message.text}
                sender={message.type || message.role}
              />
            ))}
          {isLoading && (
            <div className={`ai single_message`}>
              <div className="bubble">
                <div className="loading">
                  <div className="loading-dot"></div>
                  <div className="loading-dot"></div>
                  <div className="loading-dot"></div>
                </div>
              </div>
            </div>
          )}
          <div className="grow"></div>
          {!isLoading &&
            (suggestions && suggestions.length > 0 && suggestions[0] !== '' ? (
              <div className="suggestions-container">
                <div
                  className="suggestions"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className={`suggestion ${suggestions_style}`}
                      onClick={(e) => {
                        setN_message(suggestion);
                        handleSubmit(e);
                      }}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              </div>
            ) : null)}
          <div ref={messagesEndRef} /> {/* empty div to scroll to */}
        </div>
      </div>
      <div className="bubble-form-holder">
        <form className="form-input-feild" onSubmit={handleSubmit}>
          <input
            type="text"
            name="nitro-message"
            id="nitro-message"
            placeholder="Enter a Message..."
            value={n_message}
            onChange={(e) => setN_message(e.target.value)}
            autoComplete="off"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          ></input>

          <button
            type="submit"
            className="message-form-button"
            disabled={isLoading}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-send-horizontal"
            >
              <path d="m3 3 3 9-3 9 19-9Z" />
              <path d="M6 12h16" />
            </svg>
          </button>
        </form>
        <p className="attr">
          Powered by{' '}
          <a href="https://nitrobot.io/" target="_blank" rel="noreferrer">
            Nitrobot
          </a>
        </p>
      </div>
    </>
  );
}

export default Input;
