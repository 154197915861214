// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

// // Find all widget divs
// const botHolder = document.querySelector('#nitrbot-widget');

// ReactDOM.render(
//   <React.StrictMode>
//     <App chat_id={botHolder.dataset.chat_id} />
//   </React.StrictMode>,
//   botHolder
// );

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Global function to initialize the chat widget
window.initializeChatWidget = function(config) {
  // Ensure the config has the necessary chat_id
  if (!config || !config.chatbotId) {
    console.error('Chat widget configuration is missing the chatbotId.');
    return;
  }

  // Dynamically create a div and append to body as the chat widget container
  const chatWidgetContainer = document.createElement('div');
  chatWidgetContainer.id = 'nitrbot-widget';
  document.body.appendChild(chatWidgetContainer);

  ReactDOM.render(
    <React.StrictMode>
      <App chat_id={config.chatbotId} />
    </React.StrictMode>,
    chatWidgetContainer
  );
};

